.drive-car-ratings {
  &__title {
    @apply mb-0;
    @apply text-base;
    @apply leading-6;
  }
  &__container {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply -mt-2;
  }
}

@use '/styles/mixin/fonts.module.scss' as *;

@mixin count-label {
  @apply hidden;
  @screen md {
    @apply flex;
    @apply items-center;
    @apply mx-1;
    @include font-caption('desktop');
  }
}

.drive-label {
  &__annex-card {
    @apply flex;
    @apply items-center;
    @apply bg-black;
    @apply font-primary;
    @apply text-10px;
    @apply uppercase;
    @apply font-bold;
    @apply text-white;
    line-height: 0.688rem;
    height: 1.25rem;
    padding-left: 0.625rem;
    padding-right: 0.313rem;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      // For left and right pixels go to https://www.calculator.net/right-triangle-calculator.html
      // Set a=height, β=angle and calculate the 'b'
      // here a=20 and β=25
      border-left: 0.583rem solid black;
      border-right: 0.583rem solid transparent;
      border-bottom: 1.25rem solid transparent;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin-left: 100%;
    }
  }

  &__wrapper {
    @apply flex;
    @apply self-center;
    @apply justify-center;
    @apply min-h-6;
    @apply min-w-6;
    @apply text-xs;
    @apply border;
    @apply transition-colors;
    @apply duration-500;
    @apply rounded-full;
    @apply text-white;
    @apply px-2;
    @apply py-1;
    @apply border-transparent;
    @apply uppercase;
    @apply bg-gray-300;
  }

  &__wrapper-compact {
    @apply text-xxs;
    @apply p-1;
  }

  &__wrapper-hoverable {
    @apply cursor-pointer;

    &:hover {
      @apply text-blue-normal;
    }
  }

  &__wrapper-filter {
    @apply border-gray-400;
    @apply capitalize;
    @apply text-sm;
    @apply py-3;
    @apply px-3;
  }

  &__wrapper-active {
    @apply bg-white;
    @apply text-gray-400;
  }

  &__wrapper-inactive {
    @apply bg-gray-400;
  }
}

@use '/styles/mixin/fonts.module.scss' as *;
@use './article-card-count-label.module.scss' as *;

.drive-article-card__comment {
  &__wrapper {
    @apply flex;
    @apply items-center;
    @apply text-lg;
  }

  &__count {
    @apply block;
    @apply min-w-[0.9375rem];
    @include font-caption('desktop', 'bold');
    &__label {
      @include count-label;
    }
  }

  &__message {
    @apply ml-1;
    @include font-subtext('mobile', 'medium');

    @screen md {
      @include font-caption('desktop');
    }
  }
}

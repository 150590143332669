@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/articles.module.scss' as *;
@use '/styles/mixin/advice-articles.module.scss' as *;
.drive-annex-cards {
  &__container {
    @screen lg {
      @apply w-75;
    }
  }

  &__bottom-link {
    @include font-button('mobile');
    @apply self-end;
    @apply mr-5;

    &:hover {
      @apply underline;
      @apply text-blue-dark;
    }

    @screen md {
      @apply mr-0;
    }
  }

  &__home-page-container {
    @screen lg {
      @apply w-75;
    }

    &:global(.col-3) {
      @apply flex;
      @apply flex-col;
      @apply w-full;
      @apply pt-[30px];

      :global(.articles-wrapper) {
        @apply ml-0;
        @include thumbnail-articles();
      }

      [class*='articleAnnexCards_drive-annex-cards__heading-wrapper'] {
        @apply border-t-[1px];
        @apply border-solid;
        @apply border-gray-300;
        @apply pt-8;
        @apply ml-5;

        @screen md {
          @apply ml-0;
        }

        @screen lg {
          @apply border-none;
          @apply pt-0;
        }

        &:global(.hp-no-carlist) {
          @apply border-t-0;
        }
      }
    }

    &:global(.advice-articles) {
      @apply flex;
      @apply flex-col;
      @apply w-full;

      @screen lmd {
        @apply min-w-[400px];
      }

      @screen lg {
        @apply max-w-[400px];
      }
      :global(.articles-wrapper) {
        @apply flex-col;
        @screen md {
          @apply ml-0;
        }
        @include thumbnail-advice-articles();
        [class*='articleAnnexCard_drive-annex-card__home-page-container'] {
          @apply pr-0;
        }
        [class^='articleAnnexCards_drive-annex-cards__bottom-link'] {
          &:hover {
            @apply text-black;
          }
        }
      }
    }
  }

  &__heading-wrapper {
    @apply flex;
    @apply flex-col;
    @apply w-full;
  }

  &__heading-link {
    @apply -mt-1;
    @apply pb-5;
    @include font-subtext('mobile', 'large');
    @screen md {
      @apply hidden;
    }
  }

  &__heading {
    @apply mt-0;
    @apply mb-6;
    @include font-subtext('desktop', 'medium');
  }
}

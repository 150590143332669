@use '/styles/mixin/fonts.module.scss' as *;

.drive-page-wrapper {
  &__container {
    @apply mt-1;
    @apply px-grid2s;
    @apply z-4;

    @screen lg {
      @apply px-0;
    }

    &:global(.showrooms-page-wrapper) {
      @apply p-0;
    }

    &:global(.homepage) {
      @screen md {
        @apply px-[56px];
      }

      @screen lg {
        @apply px-0;
      }
    }
  }

  &__archive {
    @screen lg {
      @apply pl-0;
      @apply pr-0;
      :global(.main-wrapper .main-content) {
        margin-right: 0;
        margin-left: 3.4375rem;
      }
    }
  }

  &__take-over {
    @screen md {
      @apply relative;
    }
  }

  &__breadcrumbs {
    @apply relative;
    @apply z-10;
  }

  &__breadcrumbs-label-wrapper {
    @apply flex;
    @apply justify-between;

    @screen md {
      @apply block;
    }
  }

  &__name-container {
    @apply pl-2;
    @screen md {
      @apply hidden;
    }
  }
  &__name {
    @apply inline-block;
    @apply whitespace-nowrap;
    @apply bg-black;
    @apply uppercase;
    @apply text-white;
    @apply py-1.5;
    @apply px-3;
    @include font-label;
  }
}

@use '/styles/mixin/fonts.module.scss' as *;
.drive-annex-card {
  @apply flex;
  @apply flex-col;
  @apply justify-between;

  &__title {
    @apply my-0;
    @apply font-normal;
    @apply font-primary;
    @apply text-black;
  }

  &__wrapper {
  }

  &__container {
    @apply h-96px;
    @apply mb-2;
  }

  &__content {
    @apply border;
    @apply border-l-0;
    @apply border-gray-200;
    @apply pt-11px;
    @apply pr-2;
    @apply pb-1.5;
  }

  &__home-page-wrapper {
    @apply mb-7;
  }

  &__home-page-container {
    @apply h-96px;
  }

  &__home-page-content {
    @apply pt-1;
  }

  &__content-wrapper {
    @apply flex;
    @apply flex-grow;
    @apply flex-col;
    @apply justify-between;
    @apply pl-4;
  }
}

.drive-annex-cards {
  &__wrapper {
    @apply flex;
    @apply flex-col;
    @apply justify-between;
  }

  &__container {
    @apply relative;
    @apply w-full;
    @apply flex;
  }
  &__content {
    @apply flex;
    @apply flex-grow;
    @apply flex-col;
    @apply justify-between;
    @apply pl-4;
  }
  &__heading {
    @include font-text('desktop', 'small');
    @apply my-0;
    @apply text-black;
  }
  &__space {
    @apply my-0;
    @apply border-gray-300;
  }

  &__video-camera {
    @apply inline-block;
    @apply mr-1;
    @apply h-4;
    @apply w-4;
    @apply mb-0.5;
  }
}

.image-post-slider {
  &__wrapper {
    @apply mx-0 relative;
    @apply pt-6;
    @apply pb-4;
    @screen md {
      @apply pt-[2.33rem];
      @apply pb-[2.5rem];
    }
    &:before {
      content: '';
      @apply absolute;
      @apply top-0;
      @apply bottom-0;
      @apply bg-gray-100;
      @apply w-screen;
      @apply h-full;
      left: calc(-50vw + 50%);
      @apply z-0;
      @screen l {
        @apply max-w-[1440px];
        margin-left: calc((100vw - 1440px) / 2);
      }
    }

    @screen lg {
      @apply relative;
      left: calc(-50vw + 50%);
      @apply w-screen;
      &--page-takeover {
        @apply px-5;
      }
      &--page-takeover,
      &:before {
        @apply w-full;
        @apply left-0;
        @apply max-w-full;
        @apply m-0;
      }
    }
    @screen l {
      @apply max-w-[1440px];
      margin-left: calc((100vw - 1440px) / 2);
      &--page-takeover {
        @apply ml-0;
      }
    }
  }
}

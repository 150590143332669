@use '/styles/mixin/fonts.module.scss' as *;

.d__container {
  @apply flex;
  @apply items-center;
  @apply p-3;
  @apply mt-12;
  @apply border-2;
  @apply relative;
  @apply rounded-lg;
  @apply border-blue-dark;
  @apply w-full;
  @apply h-12;
  @screen md {
    @apply h-20;
    @apply p-6;
  }

  > div {
    flex: 1 0 31.33%;
  }
  &__icon-wrapper {
    @apply max-w-6;
    @screen md {
      @apply max-w-10;
    }
  }

  &__text * {
    @include font-subtext('mobile', 'large');
    @apply text-blue-dark;
    @apply mx-2;
    @screen md {
      @apply mx-4;
      @include font-heading('desktop', 'h4');
    }
  }

  &__link {
    @apply max-w-6;
    @apply ml-auto;
    > img {
      @apply w-full;
      @screen md {
        @apply hidden;
      }
    }
    @screen md {
      @apply max-w-[38%];
    }
    &--button {
      @apply hidden;
      @screen md {
        @apply bg-blue-dark;
        @apply text-white;
        @apply ml-auto;
        @apply min-w-[9.75rem];
        @apply max-w-[17rem];
        @apply flex;
        @include font-button('mobile');
        @apply text-center;
        &:hover {
          @apply bg-blue-normal;
          @apply text-white;
        }
      }
    }
    &--global {
      @apply absolute;
      @apply w-full;
      @apply h-full;
      @apply left-0;
    }
  }
}

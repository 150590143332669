.drive-progress-bar {
  @apply w-full;
  @apply bg-gray-300;
  @apply rounded-full;
  &__icon {
    @apply overflow-hidden;
    @apply rounded-l-full;
    @apply rounded-b;
    @apply pt-3;
  }
}

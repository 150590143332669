@use '/styles/mixin/fonts.module.scss' as *;
.drive-latest-posts-by-tag {
  [class*='glideSlider_glide__slides--not-mounted'] {
    @apply flex-row;
    :global(.card-wrapper) {
      flex: 0 0 33.8%;
    }
  }
  &:global(.dcoty-evergreen-carousel) {
    [class^='articlesTitle_drive-articles-title'] {
      @apply mt-0;
      @apply mb-26px;

      h3 {
        @include font-heading('mobile', 'h4');
        @apply m-0;
        @screen md {
          @include font-heading('desktop', 'h3');
        }
      }
    }
  }

  &__not-full-width {
    [class*='drive-article-card__article-thumbnail'] {
      @apply min-w-full;
      @apply h-full;
    }
    [class*='drive-article-card__thumbnail-category'],
    [class*='drive-article-card__thumbnail-category'] span {
      @apply text-xsmall #{!important};
      @apply not-italic #{!important};
      @apply font-bold #{!important};
      line-height: 15px !important;
      padding-top: 5px !important;
      @apply tracking-normal #{!important};
    }
    [class*='drive-article-card__figure'] {
      @apply h-full;
      aspect-ratio: 16/9;
    }
    [class*='drive-article-card__thumbnail__title'] {
      @apply h-[4.4rem];
      @apply mb-0;
      @apply leading-[1.3125rem]; //reduced line-height to 21px to avoid 3 lines title chopping off.
    }
    [class*='drive-article-card__thumbnail__link'] {
      @include font-subtext('desktop', 'large');
    }
    [class*='drive-article-card__author-link'],
    [class*='comment__count'],
    [class*='share__count'] {
      @apply text-black;
    }
  }
}

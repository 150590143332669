.drive-compare-side-bar {
  &__wrapper {
    @apply shadow;
    @apply mt-6;
  }
  &__title {
    @apply mt-0;
    @apply mb-5;
    @apply text-15px;
    @apply font-semibold;
  }
}

@use '/styles/mixin/videoPlayicon.module.scss' as *;

.drive-annex-card {
  &__link {
    @apply w-140px;
    @apply shrink-0;
    @apply relative;

    &:global(.homepage-article-thumbnail) {
      @apply w-auto;
      @apply h-full;
      aspect-ratio: 16/9;
    }
  }

  &__sticky {
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply z-10;
    @apply capitalize;
    @apply text-black;
    @apply bg-white/50;
    @apply px-1;
    @apply py-0;

    @apply text-9px;
    @apply not-italic;
    @apply font-semibold;
    line-height: 14px;
    @apply tracking-normal;
  }

  &__label {
    @apply absolute;
    @apply top-0;
    @apply left-0;
  }

  &__play-btn-wrapper {
    @apply absolute;
    @apply inset-0;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  &__play-icon {
    @apply text-gray-300;
    @apply text-6xl;
    @apply transition-opacity;
    @apply duration-500;
    @apply opacity-75;
    @include video-icon-dimension('small');

    &:hover {
      @apply opacity-100;
    }
  }
}

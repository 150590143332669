@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;

@mixin thumbnail-advice-articles {
  @apply flex;
  @apply flex-wrap;

  [class*='articleAnnexCard_drive-annex-cards__wrapper'] {
    @apply w-full;
    @apply mb-4;

    &:nth-of-type(1n + 7) {
      @apply hidden;
    }

    @screen md {
      max-width: 400px;
      @apply mb-[10px];
    }

    @screen lmd {
      @apply mb-[22px];
    }

    @screen lg {
      &:nth-of-type(1n + 7) {
        @apply block;
      }
    }
  }

  [class*='articleAnnexCard_drive-annex-cards__container'] {
    @apply mb-4;
    @apply h-[84px];
    @screen xs {
      @apply pr-5;
    }
    @screen md {
      @apply h-[80px];
      @apply pr-0;
    }
    @screen lmd {
      @apply h-[99px];
    }

    &:hover {
      [class*='articleAnnexCard_drive-annex-cards__heading'] {
        @apply text-blue-dark;
      }
    }
  }

  [class*='subtexts_drive-annex-card__subtext-date'] {
    @include font-caption('desktop', 'normal');
    @apply text-black;
    @apply italic;
  }

  [class*='articleCard_drive-article-card__comment__wrapper'] {
    img {
      @apply w-[13px];
      @apply h-[13px];
    }

    span {
      @apply text-black;
      @include font-caption('mobile', 'bold');
    }
  }

  [class*='articleAnnexCard_drive-annex-cards__content'] {
    @apply pr-5;
    @apply pt-0;

    @screen md {
      @apply pr-0;
    }
  }

  [class*='articleAnnexCard_drive-annex-cards__heading'] {
    @include font-subtext('mobile', 'medium');
    @include truncate(3);
  }
}

@use '/styles/mixin/fonts.module.scss' as *;

.drive-article-card__author-link {
  @apply flex;
  @apply items-center;
  @apply text-gray-400;
  @apply pl-2;
  @include font-caption('mobile', 'bold');

  @screen md {
    @include font-caption('desktop');
  }
}

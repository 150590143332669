@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/thumbnail.module.scss' as *;

.drive-guide-hero-article {
  &-category {
    @include article-category;
    @apply -mt-4;
    @apply left-auto;
  }

  &-heading {
    h3 {
      @apply text-black;
      @include font-standfirst('mobile');
      @apply mt-[28px];
      @apply min-h-10;

      @screen sm {
        @apply mt-8;
        @apply mb-0;
        @apply min-h-0;
      }

      @screen sm {
        @include font-heading('mobile', 'h4');
      }

      @screen xl {
        @include font-heading('desktop', 'h4');
      }
    }
  }

  &-description {
    @apply text-black;
    @include font-text('mobile', 'medium');

    @screen sm {
      @include font-text('desktop', 'medium');
      @apply min-h-18;
    }

    @apply mt-0;

    * {
      @apply m-0;
      @apply p-0;
    }
  }
}

@use '/styles/mixin/fonts.module.scss' as *;

.drive-rate {
  &__out-of {
    @apply mr-12;
  }

  &__container {
    &__rate {
      @apply relative;
      @apply inline-flex;
      @apply justify-center;
      @apply items-start;
    }

    &__out-of {
      @apply absolute;
      @apply right-0;
      @apply top-0;
      @apply translate-y-3;
      @apply -mt-1;

      &:global(.bottom) {
        top: 12px;
        @apply mr-0;
        @apply mt-1;
        min-width: 2rem;
        @apply -mb-1;
        @apply bottom-0;
        @apply right-0;
        @apply text-gray-400;
        @apply -left-4;
        @screen md {
          @apply mt-3;
        }
      }
    }
    &--circle-rating {
      border-radius: 50%;
      @apply min-w-[2.875rem];
      @apply min-h-[2.875rem]; //46px
      @apply flex;
      @apply items-center;
      @apply justify-center;
      @apply bg-black;
      @apply text-[1.375rem] #{!important}; //22px
    }
  }

  &__default {
    &__rate {
      @include font-heading('mobile', 'h2');
      @screen md {
        @include font-heading('desktop', 'h2');
      }
    }
    &__out-of {
      @apply translate-y-2;
      @apply translate-x-8;
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
  }
  &__compact {
    &__rate {
      @include font-heading('mobile', 'h3');
    }
    &__out-of {
      @apply translate-y-1;
      @apply translate-x-8;
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
  }
  &__big {
    &__rate {
      @include font-heading('desktop', 'h1');
    }
    &__out-of {
      @include font-subtext('desktop', 'large');
      @apply translate-y-3;
      @apply translate-x-10;
      @apply text-gray-400;
      @apply mr-0;
    }
  }

  &__heading {
    @apply leading-none;
    @apply text-5xl;
    @apply text-blue-dark;
    @apply whitespace-nowrap;
  }
  &__content {
    @apply text-sm;
    @apply font-normal;
    @apply inline-block;
    @apply text-black;
    @apply font-primary;
    @apply -ml-2;
  }
}

@use '/styles/1-settings/colors' as *;
@use '/styles/mixin/thumbnail.module.scss' as *;
@use '/styles/mixin/imageWrapper.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
.drive-compare-cards {
  @apply mb-5;
  @apply ml-0;
  @include article-thumbnail;

  &__category {
    @include article-category;
    @apply bottom-0;
    @apply -mb-3;
    @apply bg-blue-dark;
    @include font-label;
    &:after {
      border-left: 1rem solid $blue-dark;
    }
  }

  &__title-wrapper {
    @apply p-4;
    @include font-heading('mobile', 'h3');
    @apply font-medium;
    @apply bg-black;
    @apply h-full;
    @apply m-0;
    @apply text-2xl;

    h3 {
      @apply text-white;
    }
    @screen md {
      @apply pt-8;
    }
  }
  &__title-link {
    @apply text-black;
    @include font-heading('mobile', 'h3');
    @apply font-medium;
    @screen md {
      @include font-heading('desktop', 'h3');
      @apply font-medium;
    }
    @screen lg {
    }
  }

  &__figure {
    height: 11.25rem;
    @apply h-56;

    @screen md {
      height: 14.063rem;
    }

    @apply w-full;
    @apply bg-gray-100;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  &__white {
    @apply border-none;
    @apply flex-none;
    height: 17rem;

    h4 {
      @apply pt-4;
      -webkit-line-clamp: 2;
    }
    [class^='drive-compare-cards__figure'] {
      max-height: 10.5rem;
    }
  }
  &__img-wrapper {
    @include img-wrapper;
  }
}

@use '/styles/mixin/fonts.module.scss' as *;
.drive-annex-card {
  &__subtext-date {
    @include font-caption('desktop');
    @apply text-gray-400;
  }

  &__subtext-sticky {
    @include font-caption('desktop');
    @apply text-gray-400;
    @apply capitalize;
  }
}

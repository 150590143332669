@use '/styles/mixin/imageWrapper.module.scss' as *;
@use '/styles/mixin/thumbnail.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/videoPlayicon.module.scss' as *;

.drive-article-card {
  &__article-thumbnail {
    @include article-thumbnail;
    &:hover {
      [class*='articleCard_drive-article-card__thumbnail__link'] {
        @apply text-blue-normal;
      }
    }
  }

  &__figure {
    @include article-thumbnail-figure;
  }

  &__thumbnail-category {
    @include article-category;
    @include font-label;
    @apply bottom-0;
    @apply -mb-3;
  }

  &__blackLabel-tag {
    :global(.tag-label) {
      padding-right: 3px;
    }

    :global(.date) {
      padding-left: 3px;
    }
  }

  &__video-camera {
    &.drive-article-card__video-camera {
      // duplicate classname to override slick default styles
      @apply h-5;
      @apply w-5;
      @apply inline-block;
      @apply mr-1;
      @apply mb-1;
    }
  }
}

.drive-article-card__thumbnail {
  &__circular-rating {
    @apply absolute;
    @apply right-0;
    @apply m-0;
    @apply mr-3;
    @apply bottom-0;
    @apply translate-y-1/2;
  }

  &__sticky {
    @apply absolute;
    @apply top-0;
    @apply right-0;
    @apply z-10;
    @apply capitalize;
    @apply text-black;
    @apply bg-white/50;
    @apply px-2;
    @apply py-1;
    @include font-caption('mobile', 'normal');

    @screen md {
      @include font-caption('desktop', 'bold');
    }
  }

  &__content {
    @apply pl-5;
    @apply pr-3;
    @apply py-3;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply h-full;
  }

  &__title {
    @apply mr-2;
    @apply flex-grow;
    @apply max-w-full;
  }

  &__link {
    @apply text-black;
    @include font-heading('mobile', 'h3');
    @apply font-medium;
  }

  &__link-short {
    @screen md {
      @include font-subtext('desktop', 'large');
    }
  }

  &__link-long {
    @screen md {
      @include font-heading('desktop', 'h3');
      @apply font-medium;
    }
  }

  &__description {
    @include font-text('desktop', 'small');
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @apply max-h-15; // fallback for safiri
    @apply text-ellipsis;
    @apply mt-2;
    @apply mb-[0.9375rem]; //15px
    @apply overflow-hidden;
    * {
      @include font-text('desktop', 'small');
      @apply m-0;
    }
  }

  &__comments {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply text-xs;
    @apply text-gray-400;
  }

  &__img-wrapper {
    @include img-wrapper;
  }

  &__play-btn-wrapper {
    @apply absolute;
    @apply inset-0;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  &__play-icon {
    @apply text-gray-300;
    @apply text-6xl;
    @apply transition-opacity;
    @apply duration-500;
    @apply opacity-75;
    @apply w-10;
    @apply h-10;
    @include video-icon-dimension('medium');

    &:hover {
      @apply opacity-100;
    }
  }
}

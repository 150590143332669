@use '/styles/mixin/fonts.module.scss' as *;

.ips-with-year-article-card {
  @apply flex;
  @apply flex-col;
  @apply mb-10;
  @apply w-[202px];

  @screen md {
    @apply w-[271px];
    @apply px-1;
    @apply pt-4.5;
  }

  @screen lg {
    @apply w-[292px];
    @apply px-3.5;
    @apply pt-4.5;
  }

  &__image-wrapper {
    @apply relative;
    @apply h-full;
    aspect-ratio: 16/9;
    img {
      @apply h-full;
      @apply w-auto;
    }
  }

  &__content {
    @apply w-full;
    @apply mt-10;

    @screen md {
      @apply mt-15;
    }

    &--description {
      @apply overflow-hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @include font-text('mobile', 'medium');

      @screen md {
        @include font-text('desktop', 'medium');
      }
    }
  }

  &__circular-rating {
    @apply w-full;
    @apply flex;
    @apply justify-center;
    @apply translate-y-1/2;
  }
}

@use '/styles/mixin/thumbnail.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/imageWrapper.module.scss' as *;

.drive-article-card__compact-thumbnail {
  &__container {
    @include article-thumbnail-compact;
    @apply relative;
    @apply w-298px;
  }

  &__category-label {
    @include article-category-compact;
    @include font-label;
    @apply top-0;
    @apply z-10;
  }

  &__figure {
    @apply bg-gray-100;
    @apply flex;
    @apply items-center;
    @apply justify-center;

    width: 8.75rem;
    height: 5.9rem;

    &:global(.author) {
      @apply bg-white;

      img {
        @apply h-full;
      }
    }
  }

  &__content {
    @apply pl-4;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply w-full;
    @apply mx-1;
  }

  &__title {
    @include font-text('desktop', 'small');
    @apply h-full;
    @apply mt-0;
    @apply mb-1;
  }

  &__sticky {
    @apply text-black;
    @apply capitalize;
    @include font-caption('mobile', 'normal');

    @screen md {
      @include font-caption('desktop', 'normal');
    }
  }

  &__img-wrapper {
    @include img-wrapper;
  }
}

@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/articles.module.scss' as *;

.drive-articles {
  &__article-slider-container {
    @apply w-full;
    @apply mx-auto;
  }
  &__article-slider-wrapper {
    @apply flex;
    @apply flex-col;

    @screen xl {
      @apply w-full;
      @apply max-w-[1240px];
      @apply px-0;
    }
  }
  &__glide-slides {
    @include rightEdgedArticleSlides;
    @apply px-0;
    @apply relative;

    @screen lg {
      &--page-takeover {
        @apply w-full;
      }
    }
    @screen l {
      @apply max-w-full;
    }

    [class^='glideSlider_glide__track'] {
      @apply pb-8;
      //keep style the same when js disabled
      & > [class*='glideSlider_glide__slides--not-mounted'] {
        @apply flex-row;
        [class^='showroom-model-post-card-wrapper'] {
          @apply mx-2;
        }
      }
    }

    [class^='navigation_glide__bullets'] {
      @apply relative;
      @apply flex;
      @apply justify-center;
      @apply mt-3;
      @apply w-[85%];
      @screen md {
        width: 70%;
      }
      @screen lg {
        width: 50%;
      }
    }

    [class*='navigation_glide__bullet-item'] {
      @apply bg-black;
      margin: 0;
      width: 50%;
    }

    [class*='navigation_glide__bullet-item--inactive'] {
      background-color: rgba(239, 239, 239, 1) !important;
      opacity: 1 !important;
    }

    [class*='navigation_glide__arrow'] {
      @apply hidden;
    }

    [class^='articlesTitle_drive-articles-title__title'] {
      @include font-heading('mobile', 'h4');
      @screen lg {
        @include font-heading('desktop', 'h4');
      }
    }
  }
}

@use '/styles/mixin/fonts.module.scss' as *;
.drive-author-bio {
  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    height: auto;
    overflow: hidden;
    @include font-text('mobile', 'large');
    @screen md {
      @include font-text('desktop', 'medium');
    }
  }

  &__wrapper {
    @apply w-full;
    @apply max-w-4xl;
    @apply p-6;
    @apply pb-14;
    @apply border;
    @apply rounded-3xl;
    @apply border-gray-300;
    @apply mt-8;
    [class^='link_drive-link__external__text'] {
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
    [class^='authorAvatar_drive-author-avatar__container'] {
      [class^='authorAvatar_drive-author-avatar__img'] {
        @apply h-16;
        @apply max-w-xs;
      }
    }
  }
  &__details {
    @apply flex;
    @apply flex-col;
    @screen sm {
      @apply items-center;
      @apply flex-row;
      @apply justify-between;
    }
  }
  &__avatar {
    @apply flex;
    @apply mb-3;
    @apply items-center;
    @apply mb-6;
    @screen md {
      @apply mb-0;
    }
    a {
      figure {
        @apply w-16;
        @apply h-16;
      }
      img {
        @apply w-full;
        @apply h-full;
      }
    }
  }
  &__links {
    @apply pl-4;
  }
  &__link-wrapper {
    @apply font-semibold;
  }
  &__name {
    @apply text-black;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__job {
    @include font-caption('mobile', 'normal');
    @apply mt-1;
    @apply text-gray-400;
    @apply mb-0;
    @screen md {
      @include font-text('desktop', 'medium');
      @apply text-black;
    }
  }
  &__link {
    @apply py-4;
    @screen md {
      @apply py-2;
    }
    [class^='link_drive-link__external__text'] {
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
  }
}

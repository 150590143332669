@use '/styles/mixin/fonts.module.scss' as *;
@use './article-card-count-label.module.scss' as *;

.drive-article-card__share {
  &__wrapper {
    @apply flex;
    @apply items-center;
    @apply ml-5;
  }

  &__icon {
    @apply fill-current;
    @apply mr-1;
  }

  &__count {
    @apply min-w-[0.4875rem];
    @include font-caption('mobile', 'bold');

    @screen md {
      @include font-caption('desktop', 'bold');
    }
    &__label {
      @include count-label;
    }
  }
}
